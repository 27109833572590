.block-uptown {

	p {
		text-transform: uppercase;
		@include fontsize($fs-uptown);
		line-height: $lh-uptown;
		font-weight: normal;
		letter-spacing: 2px;
		color: $c-silverChalice;
	}

	a {
		display: inline-block;
		text-decoration: none;
		border-bottom: 1px solid currentColor;
	}
}