.custom-transition {
	position: relative;
	transition: all $transitionTimeVue ease-out;
	//transform: scale(1);
	opacity: 1;
	z-index: 2;
}

.custom-enter, .custom-leave {
	transition: all $transitionTimeVue ease-out;
	opacity: 0;
	transform: scale(0.5);
}

.custom-leave {
	z-index: 1;
}

.fade-transition {
	transition: opacity $transitionTimeVue ease;
}
.fade-enter, .fade-leave {
	opacity: 0;
}