
/* ---------- Top Link ---------- */
#top-link-wrapper, #scroll-link-wrapper {
	position: fixed;
	right: 27px;
	bottom: 23px;
	width: $d-topLinkBgSize;
	height: $d-topLinkBgSize;
	z-index: 1000;
	display: none;
	pointer-events: none;
	.js & {
		display: block;
		@include opacity(0);
	}

	@include query_max($bp-small - 1px) {
		display: none;
	}

	&:hover {
		@include opacity(1);
	}

	&.fadeIn {
		pointer-events: auto;
	}
}

#top-link, #scroll-link {
	border-radius: 50%;
	font-size: $fs-topLink;
	color: #fff;
	width: $d-topLinkBgSize;
	height: $d-topLinkBgSize;
	background-color: rgba($c-mineShaft, .5);
	transition: background-color $transitionTime;

	&:hover {
		background-color: rgba($c-mineShaft, 1);
	}
}

#scroll-link-wrapper {
	right: auto;
	left: 50%;
	margin-left: - $d-topLinkBgSize *.5;
	//pointer-events: none;
	opacity: 0;
}

#scroll-link {
	@extend .floating;
}