.fieldGroup {

}

.field + .field, .field + .field-faux {
	margin-top: $marginDefault * .4;
}

input[type=email], input[type=text] {
	background: #fff;
	display: block;
	width: 100%;
	line-height: $lh-input;
	padding: 9px;
	text-align: center;
	border-radius: 0;
	color: $c-mineShaft;

	@include placeholder {
		color: $c-mineShaft;
	}
}

.visuallyHidden + * {
	margin-top: 0;
}

.form--narrow {
	width: 100%;
	max-width: $d-formNarrowMaxWidth;
	margin-left: auto;
	margin-right: auto;
}

.hideable {
	margin-top: 0;
}

select {
	padding: $paddingDefault*.25;
	font-weight: 400;
	text-transform: uppercase;

	option {
		padding: $paddingDefault*.25;
		font-weight: 400;
		text-transform: uppercase;
	}
}

.checkbox-list {
	margin: 0;
	padding-left: 0;

	li {
		display: block;
		margin-top: $marginDefault * .4;
		line-height: 1.2;
		text-align: left;
	}

	input {
		margin-right: $marginDefault*.25;
	}
}

select, select option {
	max-width: 100%;
}

.field--phone {
	text-align: left;
	label, div, span, input {
		display: inline;
		width: auto;
	}
}

.phone-area {

}

.phonedetail1 {

}

.phonedetail2 {

}