.navigation {
	a {
		text-decoration: none;
		transition: color $transitionTimeFast linear, background-color $transitionTimeFast linear;
	}
}

.mainNav {
	padding-left: 0;

	li {
		display: inline-block;
		list-style-type: none;

		+ li {
			margin-left: $marginDefault;
		}
	}

	a {
		display: inline-block;
		@include fontsize($fs-nav);
		line-height: $lh-nav;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 3px;

		&.selected {
			color: $c-anzac;
		}
	}
}