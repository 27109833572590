.button, a.button {
}

.button {
	display: inline-block;
	font-size: $fs-button;
	line-height: $lh-button;
	text-decoration: none;
	padding: $paddingDefault * .75 $paddingDefault;
	font-weight: 300 !important;
	min-width: 215px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: $c-mineShaft;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f1f1+0,b9b6b6+100 */
	background: #f4f1f1; /* Old browsers */
	background: -moz-linear-gradient(top,  #f4f1f1 0%, #b9b6b6 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #f4f1f1 0%,#b9b6b6 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #f4f1f1 0%,#b9b6b6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f1f1', endColorstr='#b9b6b6',GradientType=0 ); /* IE6-9 */

	&:hover {
		background: #b9b6b6;
	}

	&.selected {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b9b6b6+0,f4f1f1+100 */
		background: #b9b6b6; /* Old browsers */
		background: -moz-linear-gradient(top,  #b9b6b6 0%, #f4f1f1 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #b9b6b6 0%,#f4f1f1 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #b9b6b6 0%,#f4f1f1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9b6b6', endColorstr='#f4f1f1',GradientType=0 ); /* IE6-9 */
	}
}

.button--dark {
	color: #fff;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3d3d3d+0,282828+100 */
	background: #3d3d3d; /* Old browsers */
	background: -moz-linear-gradient(top,  #3d3d3d 0%, #282828 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #3d3d3d 0%,#282828 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #3d3d3d 0%,#282828 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d3d3d', endColorstr='#282828',GradientType=0 ); /* IE6-9 */


	&:hover {
		background: #3d3d3d;
	}

	&.selected {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#282828+0,3d3d3d+100 */
		background: #282828; /* Old browsers */
		background: -moz-linear-gradient(top,  #282828 0%, #3d3d3d 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #282828 0%,#3d3d3d 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #282828 0%,#3d3d3d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#282828', endColorstr='#3d3d3d',GradientType=0 ); /* IE6-9 */
	}
}

.buttonHolder {
	@extend .clearfix;
	display: block;
	padding: 0 $marginDefault;

	.button {
		margin-top: $marginDefault * .2;
		margin-right: $marginDefault * .2;

		@include query_max($bp-small - 1) {
			width: 100%;
			min-width: 0;
			display: block;
		}
	}

	.button-special {
		margin-top: $marginDefault * .5;
		margin-right: $marginDefault * .5;
	}

	.button + .button {
		margin-top: $marginDefault*.5;
		margin-left: 0;
	}

	@include query_min($bp-small) {
		padding: 0;
	}
}

.buttonGroup {
	text-align: center;
	display: block;

	@include query_max($bp-mobile - 1px) {
		width: 100%;
	}
	@include query_min($bp-mobile) {
		max-width: 240px;
	}


	.button {
		display: block;
		margin: 0 auto;
	}

	.button + .button {
		margin-top: $marginDefault * .7;
	}
}

.button-listing {
	text-decoration: none;
	display: inline-block;
	padding: $paddingDefault*.7 $paddingDefault;
	border: 1px solid $c-codGray;
	@include fontsize($fs-listingButton);
	line-height: $lh-listingButton;
	text-transform: uppercase;
	font-weight: normal !important;
	color: #000;
	max-width: 300px;
	letter-spacing: 1px;
	background: transparent;

	&:hover, &.selected {
		background-color: $-altoLighter;
	}

	@include query_max($bp-mobile - 1px) {
		width: 100%;
	}
	@include query_min($bp-mobile) {
		min-width: 240px;
	}
}


.button-special {
	background: $c-neptune;
	color: $c-blueDianne;
	font-weight: bold !important;
	@include fontsize($fs-calendarButton);
	line-height: $lh-calendarButton;

	svg {
		line, polyline, path, rect, polygon, circle {
			fill: $c-blueDianne;
		}
	}

	&:hover, &.selected {
		background: $c-neptune;
		color: $c-blueDianne;

		svg {
			line, polyline, path, rect, polygon, circle {
				fill: $c-blueDianne;
			}
		}
	}

	img {
		width: auto;
		height: 18px;
	}

	.responsiveSvg {
		width: 18px;
	}

	img, .responsiveSvg {
		display: inline-block;
		margin-right: 2px;
		position: relative;
		top: -1px;
		text-align: center;
	}
}

.button-special-icon {
	border-radius: 25px;
	min-width: 0;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	padding: 0;

	.table {
		width: 49px;
		height: 49px;
	}

	img, .responsiveSvg {
		width: 22px;
	}

	img, .responsiveSvg {
		margin-right: 0;
	}
}