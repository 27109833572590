.block-logos {

}

.logo-items {
	@extend .clearfix;
	@include fontsize(0);
	max-width: $d-intermediateMaxWidth;
}

.logo-item {
	display: inline-block;
	text-align: center;
	text-decoration: none;
	width: 100%;
	vertical-align: middle;
	padding: $paddingDefault*1.5 $paddingDefault;
	margin: 0;

	@include query_min($bp-small) {
		width: 33%;
	}
}