.block-video {
	background-color: #fff;

	a {
		display: block;
		width: 100%;
		max-width: $d-videoMaxWidth;
		margin-left: auto;
		margin-right: auto;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		padding-top: 38.6%;
		position: relative;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
		color: #fff;
	}

	.background {
		//background-color: rgba($c-anzac, .75);
		@include opacity(.75);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.text {
		position: absolute;
		z-index: 2;
		top: 38.5%;
		left: 0;
		width: 100%;
		@include fontsize($fs-video);
		line-height: $lh-video;
		font-weight: 300;
		margin-bottom: $marginDefault;
		@extend .icon-play;
		&:before {
			@extend %icon-font;
		}

		span {
			margin-top: $marginDefault;
			display: none;
		}

		@include query_min($bp-small) {
			span {
				display: block;
			}

			&:before {
				@include fontsize($fs-play*.5);
			}
		}

		@include query_min($bp-mobile) {
			&:before {
				@include fontsize($fs-play);
			}
		}
	}
}