.block-quoteCards {
	background-color: #fff;

	.panelContainer {
		border: 10px solid #fff;
	}

	blockquote {
		margin-left: 0;
	}

	.text-reading {
		margin-left: auto;
		margin-right: auto;
	}
}

.quoteCards-single {
	.panel {
		width: 100%;
	}

	blockquote {
		max-width: $d-singleQuoteInteriorMaxWidth;
	}
}

.quoteCard-cardContent {
	@include fontsize($fs-card);
	line-height: $lh-card;
	text-align: left;

	footer {
		font-style: italic;
		font-weight: 300;
		text-align: right;

		span {
			display: inline-block;
			margin-top: $marginDefault*.25;
			line-height: $lh-cardAttribution2;
		}
	}
}

.quoteCards-multiple {
	.quoteCard--odd .quoteCard-cardContent {
		@include query_min($bp-mobile) {
			border-right: 5px solid #fff;
		}
	}

	.quoteCard--even .quoteCard-cardContent {
		border-top: 10px solid #fff;

		@include query_min($bp-mobile) {
			border-top: none;
			border-left: 5px solid #fff;
		}
	}
}