.block-heroSlider {
	width: 100%;
	border: 10px solid #fff;
	line-height: 0;

	@include query_min($bp-mobile) {
		padding-top: 45%;
	}

	.slides {
		height: 400px;
		width: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		margin: 0;
		border: 0;
		padding: 0;

		@include query_min($bp-mobile) {
			position: absolute !important;
			height: 100%;
		}
	}

	.slick-track, .slick-list {
		height: 100% !important;
	}

	.slide {
		display: block;
		height: 100% !important;
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		object-fit: cover;
	}

	.slide--anchor-left {
		background-position: left center;
	}

	.gridItem-logoHolder {
		position: relative;

		@include query_min($bp-mobile) {
			display: block;
			position: absolute;
			z-index: 20;
			top: 25%;
			left: 25%;
		}
	}
	
	.gridItemInner {
		border: none;
		border-bottom: 10px solid #fff;

		@include query_min($bp-mobile) {
			border: 10px solid #fff;
		}
	}
}