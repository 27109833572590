.popover {
	border: 10px solid #fff;
	border-top: none;
	padding: $paddingDefault*2.5;
	width: 100%;
	position: relative;

	.buttonHolder {
		margin-top: $marginDefault*1.5;
		text-align: center;
	}

	&.mfp-hide {
		display: block !important;
	}

	@include query_min($bp-mobile) {
		border: 10px solid #fff;
		max-width: $d-intermediateMaxWidth;
		margin-left: auto;
		margin-right: auto;
	}

	.mfp-content & {
		border: 10px solid #fff;
	}
}

@include query_min($bp-mobile) {
	.popover-container .popover,
	.popover-container .popover.mfp-hide {
		display: none !important;
	}
}

.popover-inner {
	max-width: $d-intermediateMaxWidth;
	margin: 0 auto;
}

.popover-imageHolder {
	display: none;

	@include query_min($bp-mobile) {
		display: block;
		text-align: center;
	}
}

.popover-text {
	text-align: left;
	margin-top: 0;

	@include query_min($bp-mobile) {
		margin-top: $marginDefault*2;
	}
}

.mfp-popover {
	.mfp-close {
		color: $c-mineShaft;
		background-color: #fff;
		@include opacity(1);
		margin-top: 0;
		width: 27px;
		height: 27px;
		line-height: 27px;
		top: -5px;
		right: -5px;

		&:active {
			top: -5px;
		}
	}
}

[data-component="popover-opener"] {
	cursor: pointer;
}