.card {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.card-content {
	display: table-cell;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	overflow: hidden;
}

.card-padded {
	transition: padding $transitionTime ease;
	padding: $paddingDefault*2.5 $paddingDefault*2;

	@include query_min($bp-small) {
		padding: $paddingDefault*5 $paddingDefault*4;
	}
}