.block {
	text-align: center;
}

.block-padded {
	@extend %paddingSmart;
	padding-top: $paddingDefault;
	padding-bottom: $paddingDefault;
}

.block-padded-a-little-more {
	@extend %paddingSmart;
	padding-top: $paddingDefault*1.4;
	padding-bottom: $paddingDefault*1.4;
}

.block-padded-more {
	@extend %paddingSmart;
	padding-top: 37px;
	padding-bottom: 37px;
}

.block-padded-even-more {
	@extend %paddingSmart;
	padding-top: 44px;
	padding-bottom: 44px;
}

.block-padded-extra {
	@extend .block-padded-more;

	@include query_min($bp-small) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
.block-padded-most {
	@extend .block-padded-more;

	@include query_min($bp-small) {
		padding-top: 62px;
		padding-bottom: 62px;
	}
}

.block-light {
	background-color: #fff;
}

.block-fenced {
	@extend .standardWidth;
}

.block-text + .block-logos {
	padding-top: 0;
	margin-top: -30px;
}