.mobileMenu {
	display: none;
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 999999;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: $c-mineShaft;
	background-color: rgba($c-mineShaft, .98);
	color: #fff;

	@include query_min_height($bp-mobileFlexBoxHeightMin) {
		.mobileMenuOpen & {
			display: flex;
		}
		flex-flow: column nowrap;
	}

	@extend .text-content;

	a {
		text-decoration: none;
	}

}

//---------- html classes ----------
.mobileMenuOpen {
	overflow: hidden;
}

//---------- content ----------
.mobileMenu-inner {
	@extend .text-reading;
	@extend .block-padded;
	text-align: center;
	width: 100%;

	@include query_min_height($bp-mobileFlexBoxHeightMin) {
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
		height: 100%;
	}
}

.mobileMenu-header {
	padding-top: $paddingDefault;
	line-height: 0;
	.logo {
		height: 66px;
		display: inline-block !important;
		opacity: 1 !important;
	}
	.logo-logo {
		height: 66px;
		display: inline-block !important;
		opacity: 1 !important;
	}

	@include query_min_height($bp-mobileFlexBoxHeightMin) {
		padding-top: $paddingDefault*3;
	}
}

.mobileMenu-body {
	@include query_min_height($bp-mobileFlexBoxHeightMin) {
		display: flex;
		flex-flow: column nowrap;
		flex-grow: 10;
	}
}

.mobileMenu-footer {
	@include query_min_height($bp-mobileFlexBoxHeightMin) {
		padding-bottom: $paddingDefault*3;
	}
}

.mobileMenu-social {
	//margin-top: $marginDefault*1.6;
	a {
		color: $c-emperor;
		text-decoration: none;
		@include fontsize($fs-socialIconsMobile);
		display: inline-block;
		margin-left: $marginDefault * .5;
		margin-right: $marginDefault * .5;
		line-height: 1;

		&:hover {
			color: #fff;
		}
	}

	a + a {
		margin-top: 0;
	}
}

.mobileMenu-navigation {
	line-height: $lh-default;
	padding-top: $paddingDefault;
	padding-bottom: $paddingDefault;

	@include query_min_height($bp-mobileFlexBoxHeightMin) {
		flex: 1;
		display: flex;
	}

	@include query_min_height($bp-mobileFlexBoxHeightMax) {
		padding-top: $paddingDefault*3;
	}

	li + li {
		margin-top: $marginDefault;
	}

	ul {
		padding: 0;
		width: 100%;
		@include query_min_height($bp-mobileFlexBoxHeightMin) {
			flex:1;
			flex-flow: column nowrap;
			display: flex;
		}
	}

	li {
		list-style-type: none;
		@include query_max_height($bp-mobileFlexBoxHeightMax) {
			flex:1;
			display: flex;
			vertical-align: middle;
			align-items: center;
		}
	}

	a {
		flex: 1;
		display: inline-block;
		@include fontsize($fs-navMobile);
		line-height: $lh-navMobile;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
}

//---------- buttons ----------
.mobileMenu-opener, .mobileMenu-closer {
	@include fontsize(24px);
	color: #fff;
	text-decoration: none;
	background-color: rgba($c-mineShaft,.8);
	padding: 6px;
	position: absolute;
	top: -8px;
	right: -6px;
	margin-top: 0;

	&:before {
		display: block;
	}
}


.mobileMenu-opener {
	@include query_min($bp-mobile) {
		display: none;
	}
}

.mobileMenu-closer {

}