.stripedRows {
	text-align: left;

	.stripedRow:nth-child(odd) {
		background-color: $c-silverSand;
	}

	.stripedRow:nth-child(even) {
		background-color: #fff;
	}
}