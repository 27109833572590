.block-grid {
	background-color: #fff;
}

.gridHolder {
	@extend .clearfix;
	@include fontsize(0);
	border: 5px solid #fff;
	text-align: left;
}

.gridItem {
	width: 50%;
	padding-top: 40%;
	display: inline-block;
	position: relative;

	&.hideOnMobile {
		display: none;

		@include query_min($bp-mobile) {
			display: inline-block;
		}
	}

	@include query_min($bp-mobile) {
		width: 25%;
		padding-top: 20%;
	}
}

.gridItem-double {
	width: 100%;

	@include query_min($bp-mobile) {
		width: 50%;
	}
}

.gridItem-logoHolder {
	background-color: $c-mineShaft;

	.gridItemInner {
		padding: 5% 10%;
	}
}

.gridItem-logoHolder--lighter {
	background-color: rgba($c-mineShaft,.85);
}

.gridItem-logo {
	background: transparent no-repeat center center;
	background-size: contain;
	height: 100%;
	width: 100%;
	max-width: 50%;
	margin: 0 auto;
}

.gridItemMiddle {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.gridItemInner {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	overflow: hidden;
	border: 5px solid #fff;
}

.gridItem-images {
	.slides {
		position: absolute !important;
	}

	.slick-track, .slick-list {
		height: 100% !important;
	}
}

.gridItem-images .slide,
.gridItem-imageLink,
.gridItem-videoLink {
	display: block;
	height: 100% !important;
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	object-fit: cover;
}

.gridItem-video {
	video {
		object-fit: cover;
		height: 100%;
	}
	video::-webkit-media-controls {
		display:none !important;
	}
}
.gridItem-videoInner {
	display: block;
	height: 100% !important;
	width: 100%;
}

.gridItem-videoImage a {
	&:before {
		@extend %icon-font;
		content: $icon-play;
		@include fontsize($fs-videoPlayIcon);
		color: #fff;
		position: absolute;
		top: 50%;
		width: 100%;
		left: 0;
		z-index: 10;
		text-align: center;
		margin-top: $fs-videoPlayIcon* -.5;
		text-shadow: 0 0 10px rgba(#000,.8);
		@include opacity(.8);
		transition: text-shadow $transitionTimeFast, opacity $transitionTimeFast, font-size $transitionTimeFast;

		@include query_min($bp-small) {
			@include fontsize($fs-videoPlayIconLarge);
			margin-top: $fs-videoPlayIconLarge* -.5;
		}
	}

	&:hover {
		&:before {
			text-shadow: 0 0 20px rgba(#000,.8);
			@include opacity(1);
		}
	}
}