.toggler {
	position: relative;
	padding-top: $paddingDefault * 2.35;
	padding-bottom: $paddingDefault * 2.35;

	[data-component=toggler-element] {
		display: none;
	}

	&.toggler--open {
		.toggler-button:before {
			content: $icon-circle-minus;
		}
		[data-component=toggler-element] {
			display: block;
		}
	}
}

.toggler-holder {
	padding-left: $paddingDefault*2;
	position: relative;
}

.toggler-button {
	position: absolute;
	top: -5px;
	left: 0;
	cursor: pointer;

	&:before {
		@extend %icon-font;
		content: $icon-circle-plus;
		@include fontsize($fs-toggle);
		color: $c-mineShaft;
		outline: 1px solid transparent; //fixes an svg edge cutoff issue
	}
}