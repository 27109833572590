/* fade image in after load */
.lazyloadWithFade {
	&.lazyload,
	&.lazyloading {
		opacity: 0 !important;
	}
	&.lazyloaded {
		opacity: 1 !important;
		transition: opacity $transitionTimeFast linear;
	}
}