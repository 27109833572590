.block-teamRoster {

}

.roster-items {
	@extend .clearfix;
	@include fontsize(0);
	max-width: $d-intermediateMaxWidth;
	text-align: center;
}

.roster-item {
	display: inline-block;
	text-decoration: none;
	max-width: 250px;
	vertical-align: top;
	padding: $paddingDefault*1.25 $paddingDefault $paddingDefault;
	margin: 0;
	color: $c-shuttleGray;
}

.roster-name {
	display: block;
	@include fontsize($fs-rosterName);
	font-weight: bold;
	margin-top: $marginDefault*1.4;
	line-height: $lh-rosterName;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.roster-title {
	display: block;
	@include fontsize($fs-rosterTitle);
	font-weight: normal;
	line-height: $lh-rosterTitle;
	margin-top: $marginDefault * .3;
	text-transform: uppercase;
	letter-spacing: 1px;
}

