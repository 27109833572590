.header {
	@extend .clearfix;
	color: #fff;
	box-shadow: 0 0 35px 10px rgba(#000, .2);
	width: 100%;
	z-index: 1000;
	top: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;

	.navigation {
		position: relative;
		z-index: 2;
		margin-top: $marginDefault; //make sure and update in site.js fixedHeader()
		display: none;

		@include query_min($bp-mobile) {
			display: block;

			.scrolled & {
				margin-top: 13px; //make sure and update in site.js fixedHeader()
			}
		}
	}
}

.headerInner {
	@extend .clearfix;
	@extend .standardWidth;
	text-align: left;
	position: relative;
	line-height: 0;

	@include query_min($bp-mobile) {
		text-align: center;
	}
}

.logo {
	text-decoration: none;
	display: inline-block;
	@include fontsize(0);
	position: relative;
	z-index: 1;
	transition: height $transitionTimeFast linear;
	height: 32px;

	img {
		margin: 0 auto;
	}

	@include query_min($bp-mobile) {
		width: 233px;
		height: 84px;
	}
}

.logo-logo {
	@include opacity(1);
	transition: all $transitionTimeFast linear;
	position: relative;
	top: -7px;
	width: auto !important;
	height: 32px;
	display: block;

	@include query_min($bp-mobile) {
		top: 0;
		height: 84px;
	}
}

.scrolled {
	.logo {
		height: 63px;
	}

	.logo-logo {
		height: 63px;
	}
}