.block-textCarouselTimeline {
	.slide {
		margin-top: 0;
		text-align: left;
		padding: 0 $paddingDefault*1.75;

		> div {
			padding: $paddingDefault*3.25 0 $paddingDefault*2;
		}
	}

	.slide + .slide {
		display: none;
	}

	.slick-initialized .slide + .slide {
		display: block;
	}

	.circleLabel {
		margin-top: $marginDefault*2.75;
	}

	&:after {
		top: 0;
		height: auto;
		bottom: 0;
	}

	&.isTimelineFirst {
		&:after {
			top: 240px;
			bottom: 0;
		}
	}

	.buttonHolder {
		text-align: center;
	}
}

.textCarousel-wrapper {
	max-width: $d-textCarouselMaxWidth;
	margin: $marginDefault auto 0;
}