.block-textImageTimeline {
	padding-left: 0;
	padding-right: 0;
	position: relative;
	padding-top: $paddingDefault*2;
	padding-bottom: $paddingDefault*2;

	&.isFirstOfBg {
		padding-top: $paddingDefault*5;
	}
	&.isLastOfBg {
		padding-bottom: $paddingDefault*5;
	}

	.panelContainer {
		position: relative;
		z-index: 3;
		margin-top: 0;
	}

	.panel {
		margin-top: $marginDefault*2;

	}

	.card-content {
		padding-left: $paddingDefault;
		padding-right: $paddingDefault;
	}

	@include query_min($bp-mobile) {
		.panelContainer {
			margin-top: $marginDefault*2;
		}
	}
	@include query_min($bp-overview) {
		.panelContainer {
			margin-top: 0;
		}

		.panel {
			margin-top: 0;
		}

		.circleLabel {
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: - $fs-circleLabelIcon * .5;
			margin-left: - $fs-circleLabelIcon * .5;
			z-index: 2;
		}

		&.isFirstOfBg .circleLabel {
			margin-top: (- $fs-circleLabelIcon*.5) + ($paddingDefaultPx*1.5);
		}
		&.isLastOfBg .circleLabel {
			margin-top: (- $fs-circleLabelIcon*.5) - ($paddingDefaultPx*1.5);
		}
		&.isFirstOfBg.isLastOfBg .circleLabel {
			margin-top: - $fs-circleLabelIcon * .5;
		}
	}

	&.isTimelineFirst {
		&:after {
			top: 213px;
		}
	}
}

.textImageTimeline-headings {
	@include fontsize($fs-timelineHeading);
	line-height: $lh-timelineHeading;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 2px;
	max-width: $d-timelineMaxWidth;
	margin: 0 auto;
	color: $c-shuttleGray;
}

.textImageTimeline-heading {
	position: relative;
}

.textImageTimeline-heading + .textImageTimeline-heading {
	padding-top: $paddingDefault;

	&:before {
		content: '';
		width: 20px;
		height: 3px;
		background-color: $c-shuttleGray;
		position: absolute;
		top: -1px;
		left: 50%;
		margin-left: -10px;
	}
}

.textImageTimeline-inner {

}