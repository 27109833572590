.block-tabs {
	padding-bottom: $paddingDefault*1.5;
}

.tab {
	display: none;
	margin-top: 0;

	&.selected {
		display: block;
	}
}

.tab-text {
	text-align: left;
}