.block-text {
	position: relative;

	p {
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}

	table {
		margin-left: auto;
		margin-right: auto;

		th, td {
			padding: 0 $paddingDefault*.75;
		}
	}

	.text-content {
		position: relative;
		z-index: 2;
		text-align: center;
	}

	.text-bg {
		position: absolute;
		top: 75px;
		left: 50%;
		width: 100%;
		height: calc(100% - 75px);
		max-width: 1200px;
		z-index: 1;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100% auto;
		transform: translateX(-50%);
	}
}