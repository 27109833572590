.block-social {
	a {
		color: $c-alto;
		text-decoration: none;
		@include fontsize($fs-socialIconsBlock);
		display: inline-block;
		margin-left: $marginDefault * .5;
		margin-top: 0;
		line-height: 1;
	}
}

.social {
	display: none;

	@include query_min($bp-standardWidth) {
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 3;
	}

	a {
		color: #fff;
		text-decoration: none;
		@include fontsize($fs-socialIconsHeader);
		display: inline-block;
		margin-left: $marginDefault * .25;
		line-height: 1;

		&:before {
			outline: 1px solid transparent;
		}

		&:hover {
			color: #fff;
		}
	}
}