.block-pagination {
	a, span {
		text-transform: uppercase;
		font-weight: normal !important;
		letter-spacing: 1px;
		@include fontsize($fs-pagination);
		display: inline-block;
		padding: $paddingDefault*.5;
		margin: 0 $marginDefault*.1;
		line-height: 1;
		color: #000;
	}

	a {
		text-decoration: none;
	}

	span {
		@include opacity(.4);
		cursor: default;
	}
}