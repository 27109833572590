.detail {
	background-color: $c-alto;
	@extend .clearfix;

	article {
		@include fontsize($fs-detailBody);
		line-height: $lh-detailBody;
		margin-top: -5px;

		h1 {
			@include fontsize($fs-detailHeading);
			font-weight: bold;
			line-height: $lh-detailHeading;
		}
	}

	aside {
		margin-top: $marginDefault * 2;
		text-align: center;
	}
}

.detail-inner {
	background-color: $c-gallery;
	border: 10px solid $c-alto;
	margin: 0 auto;
	@extend .clearfix;
	padding-left: $paddingDefault;
	padding-right: $paddingDefault
}

.detail-content {
	max-width: $d-detailMaxWidth;
	margin: 0 auto;
}

.detail-layoutSplit {
	@include query_min($bp-mobile) {
		aside {
			float: left;
			margin-top: 0;
			padding-right: $paddingDefault*2;
			width: 37%;
			max-width: 280px;
			text-align: left;

			.buttonGroup {
				margin-left: 0;
				margin-right: 0;
			}

			.listing-date + .buttonGroup,
			.listing-date + .backLink {
				margin-top: $marginDefault*2.3;
			}

			.backLink {
				position: relative;
				top: -14px;
			}

			* + .backLink {
				top: 0;
			}

			.buttonGroup + .backLink {
				position: static;
				top: 0;
				margin-top: $marginDefault*1.4;
			}
		}

		article {
			width: 62.605%;
			float: right;
		}
	}
}