#wrapper {
	position: relative;
	padding: 0;
	padding-top: $d-headerHeightMobile;
	transition: padding-top $transitionTimeFast linear;

	@include query_min($bp-mobile) {
		padding-top: $d-headerHeight;
	}

	.scrolled & {
		@include query_min($bp-mobile) {
			padding-top: $d-headerHeightScrolled;
		}
	}
}

.header {
	position: relative;
	z-index: 100;
	height: $d-headerHeightMobile;
	transition: height $transitionTimeFast linear;

	@include query_min($bp-mobile) {
		height: $d-headerHeight;
	}

	.scrolled & {
		@include query_min($bp-mobile) {
			height: $d-headerHeightScrolled;
		}
	}
}



.pageContent {
	width: 100%;
}

.content {
	transition: opacity $transitionTimeFast linear;
	@extend .clearfix;
}

#pjax-container {
	background-color: $c-mineShaft;
	position: relative;
	&.pjaxLoading {
		.content {
			@include opacity(.85);
		}
	}
}