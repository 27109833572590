$arr-backgroundsDark: () !default;
$arr-backgroundsLight: () !default;

@each $name, $color in $arr-backgroundsDark {
	.bg-#{$name} {
		background-color: $color;
		color: #fff;
	}
}

@each $name, $color in $arr-backgroundsLight {
	.bg-#{$name} {
		background-color: $color;
		color: $c-text;

		h1, .fauxH1,
		h2, .fauxH2 {
			color: $c-redDamask;
		}
	}
}