.miniCalendar {
	background-color: $c-wedgewood;
	color: $c-glacier;
	position: relative;
	@extend .backgroundCover;

	a {
		text-decoration: none;
	}
}

.miniCalendar-wrapper {

}

.miniCalendar-inner {
	max-width: $d-calendarMaxWidth;
	position: relative;
	z-index: 2;
	letter-spacing: .7px;
}

.miniCalendar-bg {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($c-wedgewood, .75);
}

.miniCalendar-years {
	@include fontsize($fs-calYear);
	@extend .clearfix;
	position: relative;
	width: 100%;
	line-height: 1.1;

	* + * {
		margin-top: 0;
	}
}

.miniCalendar-thisYear {
	font-weight: normal;
	color: #fff;
}
.miniCalendar-prevYear {
	padding-left: $paddingDefault*1.2;
	float: left;
}
.miniCalendar-nextYear {
	padding-right: $paddingDefault*1.2;
	float: right;
}

.miniCalendar-months-select {
	@include query_min($bp-calendarMonths) {
		display: none;
	}
}

.miniCalendar-months {
	padding-bottom: 6px;
	display: none;
	width: 100%;
	height: 50px;

	a {
		text-align: center;
		@include fontsize($fs-calMonth);
		display: block;
		width: percentage(1/12);
		float: left;
		text-transform: uppercase;

		+ a {
			margin-top: 0;
		}
	}

	&:after {
		position: absolute;
		content: '';
		height: 1px;
		background-color: $c-sanJuan;
		bottom: 0;
		left: $paddingDefault*1.2;
		right: $paddingDefault*1.2;
	}

	@include query_min($bp-calendarMonths) {
		display: block;
	}
}

.miniCalendar-thisMonth {
	font-weight: normal;
	color: #fff;
}

.miniCalendar-days {
	@extend .clearfix;
	font-size: 0;
	width: 100%;
	text-align: center;

	@include query_min($bp-mobile) {
		text-align: left;
	}

	span {
		@include fontsize($fs-calDay);

		padding-left: $paddingDefault*1.2;
		padding-right: $paddingDefault*1.1;

		@include query_min($bp-calendarMonths) {
			width: percentage(1/16);
		}

		@include query_min($bp-mobile) {
			float: left;
		}
	}

	* + * {
		margin-top: 0;
	}

	.today {
		font-weight: normal;
	}

	a {
		font-weight: normal;
		color: #fff;
	}
}

.miniCalendar-buttons {
	margin-top: $marginDefault*1.8;
}

.miniCalendar-mobileToggle {
	.button {
		margin-top: 0;
	}
}

.miniCalendar-listingsWrapper {
	background-color: $c-alto;
}