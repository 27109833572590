// ------- TYPOGRAPHIC STYLES
body {
	@include fontsize($fs-default);
	font-family: $ff-default;
}

a {
	color: currentColor;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

h1, .fauxH1,
h2, .fauxH2,
h3, .fauxH3,
h4, .fauxH4,
h5, .fauxH5,
h6, .fauxH6 {
	font-family: $ff-default;
	font-weight: 300;
}

h1, .fauxH1 {
	@include fontsize($fs-h1Small);
	@include query_min($bp-mobile) {
		@include fontsize($fs-h1);
	}
	line-height: $lh-h1;
	text-transform: uppercase;
	letter-spacing: 2px;
}
h2, .fauxH2 {
	@include fontsize($fs-h2Small);
	@include query_min($bp-mobile) {
		@include fontsize($fs-h2);
	}
	line-height: $lh-h2;
	text-transform: uppercase;
	letter-spacing: 2px;
}
h3, .fauxH3 {
	@include fontsize($fs-h3Small);
	@include query_min($bp-mobile) {
		@include fontsize($fs-h3);
	}
	text-transform: uppercase;
	line-height: $lh-h3;
}
h4, .fauxH4 {
	@include fontsize($fs-h4);
}
h5, .fauxH5 {
	@include fontsize($fs-h5);
}
h6, .fauxH6 {
	@include fontsize($fs-h6);
}

.text-callout {
	@include fontsize($fs-callout);
	line-height: $lh-callout;
}