.block-projectSections {
	background-color: $c-scorpion;
	color: #fff;
}

.sectionListings {
	@extend .clearfix;
	text-align: center;
	@include fontsize(0);
}

.sectionListing {
	display: inline-block;
	text-decoration: none;
	padding: $paddingDefault*.5 $paddingDefault*1.5 0;
	position: relative;
	vertical-align: top;
	margin-top: $marginDefault;

	@include query_min($bp-mobile) {
		width: 226px;
	}

	&:hover {
		.sectionListing-icon {
			@include opacity(1);
		}
	}

	&.active {
		.sectionListing-icon {
			@include opacity(1);
			text-shadow: 0 0 15px rgba(#fff,.35);
		}
	}
}

@include query_min($bp-mobile) {
	.sectionListing + .sectionListing {
		&:before {
			content: '';
			position: absolute;
			border-left: 1px solid #fff;
			left: 0;
			height: 30%;
			top: 20%;
		}
	}
}

.sectionListing-icon {
	display: inline-block;
	outline: 1px solid transparent;
	transition: opacity $transitionTimeFast ease;
	@include opacity(.5);

	img {
		width: auto;
		height: 114px;
	}

	.responsiveSvg {
		width: 114px;
	}
}

.sectionListing-separator {
	display: block;
	clear: both;
	margin-top: 0;
}

.sectionListing-title {
	clear: both;
	display: inline-block;
	text-transform: uppercase;
	@include fontsize($fs-sectionListing);
	line-height: $lh-sectionListing;
	font-weight: 300;
	max-width: 120px;
	letter-spacing: 2px;
	margin-top: $marginDefault;

	@include query_min($bp-mobile) {
		margin-top: $marginDefault*1.5;
	}
}