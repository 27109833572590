@font-face {
	font-family: $ff-icons;
	src:
		url('#{$icomoon-font-path}/#{$ff-icons}.ttf?#{$iconHash}') format('truetype'),
		url('#{$icomoon-font-path}/#{$ff-icons}.woff?#{$iconHash}') format('woff'),
		url('#{$icomoon-font-path}/#{$ff-icons}.svg?#{$iconHash}##{$ff-icons}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], .icon, %icon-font {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: $ff-icons !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-youtube {
	&:before {
		content: $icon-youtube;
	}
}
.icon-uptown {
	&:before {
		content: $icon-uptown;
	}
}
.icon-envelope {
	&:before {
		content: $icon-envelope;
	}
}
.icon-print {
	&:before {
		content: $icon-print;
	}
}
.icon-file-text {
	&:before {
		content: $icon-file-text;
	}
}
.icon-arrow-down {
	&:before {
		content: $icon-arrow-down;
	}
}
.icon-arrow-up {
	&:before {
		content: $icon-arrow-up;
	}
}
.icon-circle-outline {
	&:before {
		content: $icon-circle-outline;
	}
}
.icon-chevron-left-thin {
	&:before {
		content: $icon-chevron-left-thin;
	}
}
.icon-chevron-right-thin {
	&:before {
		content: $icon-chevron-right-thin;
	}
}
.icon-triangle-down {
	&:before {
		content: $icon-triangle-down;
	}
}
.icon-circle {
	&:before {
		content: $icon-circle;
	}
}
.icon-circle-minus {
	&:before {
		content: $icon-circle-minus;
	}
}
.icon-circle-plus {
	&:before {
		content: $icon-circle-plus;
	}
}
.icon-play {
	&:before {
		content: $icon-play;
	}
}
.icon-post-oak-boulevard {
	&:before {
		content: $icon-post-oak-boulevard;
	}
}
.icon-transit-centers {
	&:before {
		content: $icon-transit-centers;
	}
}
.icon-west-loop-bus-lane {
	&:before {
		content: $icon-west-loop-bus-lane;
	}
}
.icon-boulevard {
	&:before {
		content: $icon-boulevard;
	}
}
.icon-popover {
	&:before {
		content: $icon-popover;
	}
}
.icon-balloon {
	&:before {
		content: $icon-balloon;
	}
}
.icon-facebook {
	&:before {
		content: $icon-facebook;
	}
}
.icon-instagram {
	&:before {
		content: $icon-instagram;
	}
}
.icon-twitter {
	&:before {
		content: $icon-twitter;
	}
}
.icon-menu {
	&:before {
		content: $icon-menu;
	}
}
.icon-close {
	&:before {
		content: $icon-close;
	}
}
.icon-chevron-right {
	&:before {
		content: $icon-chevron-right;
	}
}
.icon-chevron-left {
	&:before {
		content: $icon-chevron-left;
	}
}