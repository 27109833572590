//---------- clearfix ----------
.clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}

	//zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

//---------- padding/margins ----------
%lobotomize {
	* + * {
		margin-top: $marginDefault;
	}

	//fixes a firefox bug
	br {
		margin: 0 !important;
	}
}

//---------- text ----------
%hideText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

%antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%defaultText {
	@include fontsize($fs-default);
	line-height: $lh-default;
}

%noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.text-content {
	@extend %defaultText;
	@extend %lobotomize;

	ul, ol, blockquote {
		margin-left: $marginDefault;
		text-align: left;
	}

	blockquote {
		margin-left: $marginDefault*2;
	}

	li {
		margin-top: 0;
	}

	p + ul, p + ol {
		margin-top: 0;
	}
}

.text-reading {
	max-width: $d-readingMaxWidth;
	margin-left: auto;
	margin-right: auto;
}

//---------- images ----------
.img-stretch {
	width: 100%;
}

.backgroundCover {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

//---------- media object ----------
//media block
.mb {
	overflow: hidden;
	//_overflow: visible;
	//zoom: 1;
}
//media body (content)
.mb-body {
	margin-top: 0;
	overflow: hidden;
	//_overflow: visible;
	//zoom: 1;
}
//media media (image)
.mb .mb-media, %mb-media {
	float: left;
	margin-right: $marginDefault;
	margin-top: 0;
}
.mb .mb-media img {
	display: block;
}
.mb .mb-media--alt, %mb-media--alt {
	float: right;
	margin-left: $marginDefault;
	margin-top: 0;
}

//---------- hidden ----------
.hidden {
	display: none !important;
	visibility: hidden;
}

.visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.invisible {
	visibility: hidden;
}

.fader {
	transition: opacity $transitionTime linear;
}

.transparent {
	@include opacity(0);
}

.ignored {
	margin: 0 !important;
}

//---------- super text ----------
.sup {
	font-size: 70%;
	display: inline-block;
	vertical-align: top;
}

//---------- zoom in cursor ----------
.js {
	.zoom-in, a.zoom-in {
		cursor: pointer;
		cursor: -webkit-zoom-in;
		cursor: -moz-zoom-in;
		cursor: zoom-in;
	}
}

//---------- hide on mobile ----------
.hideOnMobile {
	display: none;
	@include query_max($bp-mobile - 1px) {
		& + * {
			margin-top: 0;
		}
	}
	@include query_min($bp-mobile) {
		display: block;
	}
}

//---------- mobileOnly ----------
.mobileOnly {
	@include query_min($bp-mobile) {
		display: none !important;
		& + * {
			margin-top: 0;
		}
	}
}

//---------- hackish centering ----------
.table {
	display: table;
}

.row {
	display: table-row;
}

.cell {
	display: table-cell;
	vertical-align: middle;
}

//---------- loading ----------
.loading {
	min-height: 80px;
	background: url(/assets/svg/ajax-loader-white.svg) no-repeat center center;
}

.loading--tall {
	@extend .loading;
	min-height: 400px;
}

.loading--subtle {
	min-height: 70px;
	background: url(/assets/svg/ajax-loader-glacier-small.svg) no-repeat center center;
}

//---------- widths ----------
.fullWidth {
	width: 100%;
	margin: 0 auto;
}

.fenceWidth {
	width: 100%;
	max-width: $bp-fence;
	margin-left: auto;
	margin-right: auto;
}

.standardWidth {
	@extend .clearfix;
	max-width: $d-standardWidth;
	margin-left: auto;
	margin-right: auto;
}

.contentWidth {
	@extend .clearfix;
	max-width: $bp-fence;
	margin-left: auto;
	margin-right: auto;
}

%paddingSmart {
	padding-left: $paddingDefault;
	padding-right: $paddingDefault;

	@include query_min( $d-standardWidth ) {
		padding-left: 0;
		padding-right: 0;
	}
}

//---------- animation ----------
@keyframes fadeIn {
	from { @include opacity(0); visibility: hidden; }
	to { @include opacity(1); visibility: visible; }
}

@keyframes fadeOut {
	from { @include opacity(1) }
	to { @include opacity(0) }
}

//---------- vue ----------
[v-cloak] {
	display: none;
}

//---------- fitie ----------
video.cover {
	object-fit: cover;
}

//---------- back, prev, and next links ----------
.backLink {
	display: inline-block;
	text-decoration: none;
	color: $c-gray;
	@include fontsize($fs-backLink);
	line-height: $lh-backLink;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;

	&:before {
		@extend %icon-font !optional;
		content: $icon-chevron-left;
		font-size: 70%;
		display: inline-block;
		margin-right: 8px;
		vertical-align: middle;
	}
}

.prevLink:before, .nextLink:after {
	@extend %icon-font !optional;
	font-size: 50%;
	vertical-align: middle;
	display: inline-block;
	position: relative;
	top: -2px;
}

.prevLink {
	&:before {
		content: $icon-chevron-left;
		margin-right: 8px;
	}
}

.nextLink {
	&:after {
		content: $icon-chevron-right;
		margin-left: 8px;
	}
}

//---------- floating effect ----------
//from http://www.justinaguilar.com/animations/#

.floating{
	animation-name: floating;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(10%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);
	}
	50% {
		-webkit-transform: translateY(10%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}
