.circleLabel {
	position: relative;
	width: $fs-circleLabelIcon;
	height: $fs-circleLabelIcon;
	margin: 0 auto;
	z-index: 5;

	&:before {
		@extend %icon-font;
		content: $icon-circle-outline;
		@include fontsize($fs-circleLabelIcon);
		text-shadow: 0 0 5px rgba(#fff,.35);
	}

	h2 {
		position: absolute;
		top: ($fs-circleLabelIcon - $fs-circleLabel) *.5;
		@include fontsize($fs-circleLabel);
		font-weight: bold;
		text-align: center;
		width: 100%;
		letter-spacing: 2px;
		text-shadow: 0 0 15px rgba(#fff,.35);
	}
}

.block-timeline {
	&:after {
		content: '';
		width: 1px;
		height: 100%;
		background-color: $c-silverChalice;
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 1;
	}

	&.bg-sanJuan {
		.text-content {
			position: relative;
			z-index: 2;
		}
		&:after {
			background-color: $c-horizon;
		}
	}
	&.bg-mistGray {
		.circleLabel {
			color: #fff;
		}
		&:after {
			background-color: #fff;
		}
	}
	&.bg-alto, &.bg-white {
		.circleLabel {
			color: $c-gray2;
		}
		&:after {
			color: $c-gray2;
		}
	}
}