//---------- Colors ----------
$c-mineShaft: #323232; //header bg, quote card
$c-sanJuan: #29526d; //block bg
$c-horizon: #5685a3; //project sections divider
$c-alto: #e0e0e0; //block bg, map listing bg, row
$-altoLighter: #d4d4d4; //listing button bg
$c-chicago: #595a54; //quote card
$c-friarGray: #83827d; //quote card, pagination current
$c-tundora: #424242; //quote card
$c-silver: #ccc; //map listing bg
$c-mistGray: #bebdb3; //block bg
$c-sprout: #cadaa9; //block bg
$c-emperor: #555;
$c-thunder: #231f20; //popup bg
$c-anzac: #e4a752; //video overlay
$c-juniper: #6b8a8c; //video overlay
$c-silverSand: #cbcccc; //row
$c-shuttleGray: #636466; //roster text
$c-gallery: #ebebeb; //action blocks bg
$c-codGray: #171717; //listing button border
$c-gray: #8f8f8f; //back links
$c-gray2: #868686; //back links
$c-wedgewood: #427894; //calendar bg
$c-glacier: #7ba9c0; //calendar text
$c-neptune: #74a2b8; //calendar button bg
$c-blueDianne: #1e4153; //calendar button text
$c-silverChalice: #acacac;
$c-astral: #3c7ca0;
$c-scorpion: #5c5c5c;
$c-tangerine: #F88D2D;
$c-yellow: #FFC844;
$c-springGreen: #71A850;
$c-teal: #009383;
$c-slate: #3D4543;
$c-lightGray: #BBC6C3;

//new colors from latest update
$c-olivine: #8cb07e;
$c-selectiveYellow: #f8bb07;
$c-redDamask: #d45845;

//text color
$c-text: $c-mineShaft;

//selection background color
$c-selection: $c-text;

//forms
$c-error: red;

//---------- Background Colors ----------
$arr-backgroundsDark: (
	sanJuan: $c-sanJuan,
	chicago: $c-chicago,
	friarGray: $c-friarGray,
	tundra: $c-tundora,
	mineShaft: $c-mineShaft,
	thunder: $c-thunder,
	anzac: $c-anzac,
	juniper: $c-juniper,
	astral: $c-astral,
	//new
	tangerine: $c-tangerine,
	yellow: $c-yellow,
	springGreen: $c-springGreen,
	teal: $c-teal,
	slate: $c-slate,
	lightGray: $c-lightGray,
	olivine: $c-olivine,
	selectiveYellow: $c-selectiveYellow,
	redDamask: $c-redDamask
);
$arr-backgroundsLight: (
	white: #fff,
	alto: $c-alto,
	mistGray: $c-mistGray,
	sprout: $c-sprout
);

//---------- Spacing ----------
$marginDefaultPx: 20px;
$marginDefault: rems($marginDefaultPx);
$paddingDefaultPx: 20px;
$paddingDefault: rems($paddingDefaultPx);

//---------- Timing ----------
$transitionTime: .5s; //update in site.js if changed
$transitionTimeFast: .25s; //update in site.js if changed
$transitionTimeFaster: .1s;
$transitionTimeVue: .3s;

//---------- Typography ----------
$ff-default: 'Lato', Arial, sans-serif;

//font sizes
$fs-default: 18px;
$lh-default: 35/18;

//callout
$fs-callout: 21px;
$lh-callout: 35/21;

//card
$fs-card: 21px;
$lh-card: 35/21;
$lh-cardAttribution2: 1.1;

//nav
$fs-nav: 15px;
$lh-nav: 1.1;
$fs-navMobile: 20px;
$lh-navMobile: 1.1;

//video
$fs-video: 25px;
$lh-video: 1.1;
$fs-play: 94;

//social icons
$fs-socialIconsHeader: 18px;
$fs-socialIconsBlock: 22px;
$fs-socialIconsMobile: 24px;

//heading
$fs-heading: 16px;
$lh-heading: 1.23;

//buttons
$fs-button: 16px;
$lh-button: 1.1;
$fs-listingButton: 14px;
$lh-listingButton: 20px;
$fs-calendarButton: 14px;
$lh-calendarButton: 1.3;

//back link
$fs-backLink: 14px;
$lh-backLink: 1;

//input
$fs-input: 18px;
$lh-input: 1;

//section listing
$fs-sectionListing: $fs-heading;
$lh-sectionListing: 1.2;

//map point balloons
$fs-mapBalloonIcon: 44px;
$fs-mapBalloonNumber: 14px;

//toggle icons
$fs-toggle: 31px;

//roster
$fs-rosterName: $fs-heading;
$lh-rosterName: $lh-heading;
$fs-rosterTitle: 14px;
$lh-rosterTitle: 1.28;

//listing block
$fs-listingTitle: $fs-heading;
$lh-listingTitle: $lh-heading;
$fs-listingLabel: $fs-heading;
$lh-listingLabel: $lh-heading;
$fs-listingSummary: 21px;
$lh-listingSummary: 1.66667;
$fs-listingDay: 66px;
$lh-listingDay: 1;

//mini calendar
$fs-calYear: 23px;
$fs-calMonth: 23px;
$fs-calDay: 20px;

//detail
$fs-detailHeading: $fs-heading;
$lh-detailHeading: $lh-heading;
$fs-detailBody: 18px;
$lh-detailBody: 25/18;

//pagination
$fs-pagination: 14px;

//timeline
$fs-circleLabelIcon: 114px;
$fs-circleLabel: 18px;
$fs-timelineHeading: $fs-heading;
$lh-timelineHeading: 25/16;

//uptown footer
$fs-uptown: 14px;
$lh-uptown: 1.3;

//headings
$fs-h1: 40px;
$fs-h1Small: 30px;
$lh-h1: 1.2;
$fs-h2: 40px;
$fs-h2Small: 30px;
$lh-h2: 1.1;
$fs-h3: 27px;
$fs-h3Small: 25px;
$lh-h3: 1.2;
$fs-h4: 18px;
$fs-h5: 17px;
$fs-h6: 16px;

//arrow
$d-topLinkBgSize: 40px;
$fs-topLink: 14px;

//play icon
$fs-videoPlayIcon: 64px;
$fs-videoPlayIconLarge: 128px;

//---------- Dimensions ----------
$d-standardWidth: 1276px; //update 'full' in /config/images.php if changed
$d-readingMaxWidth: 875px; //update 'reading' in /config/images.php if changed
$d-videoMaxWidth: 1092px;
$d-mapBalloonIconWidth: 36px;
$d-mapBalloonIconHeight: $fs-mapBalloonIcon;
$d-detailMaxWidth: 952px;
$d-intermediateMaxWidth: 1000px;
$d-formNarrowMaxWidth: 332px;
$d-calendarMaxWidth: 1150px;
$d-textCarouselMaxWidth: 1130px;
$d-timelineMaxWidth: 390px;
$d-singleQuoteInteriorMaxWidth: 575px;

$d-headerHeightMobile: 59px;
$d-headerHeight: 149px;
$d-headerHeightScrolled: 111px;

//---------- Breakpoints ----------
$bp-small: 500px;
$bp-mobile: 850px; //if changed, update in site.js
$bp-fence: 1920px;
$bp-standardWidth: $d-standardWidth + 2 * $paddingDefaultPx;
$bp-calendarMonths: $d-calendarMaxWidth + 140;
$bp-overview: 1150px;
$bp-mobileFlexBoxHeightMin: 500px;
$bp-mobileFlexBoxHeightMax: 800px;

//---------- Slider/Lightbox Arrows ----------
$arrowOffset: 0;
$fs-arrowHeight: 35px;
$fs-arrowWidth: 18px;
$c-arrows: #fff;
$c-arrowsLightbox: #fff;
$c-arrowsLightboxHover: #fff;
$c-arrowsHover: lighten(#fff, 25%);

//---------- Font Icons ----------
$ff-icons: 'boulevard';
$icomoon-font-path: "/assets/fonts";
$iconHash: 'c91udg';

$icon-youtube: "\e910";
$icon-uptown: "\e90f";
$icon-envelope: "\1f582";
$icon-print: "\1f5b6";
$icon-file-text: "\1f5b9";
$icon-arrow-down: "\2b07";
$icon-arrow-up: "\2b06";
$icon-circle-outline: "\26aa";
$icon-chevron-left-thin: "\3c";
$icon-chevron-right-thin: "\3e";
$icon-triangle-down: "\25bc";
$icon-circle: "\26ab";
$icon-circle-minus: "\2d";
$icon-circle-plus: "\2b";
$icon-play: "\25b7";
$icon-post-oak-boulevard: "\e901";
$icon-transit-centers: "\e902";
$icon-west-loop-bus-lane: "\e906";
$icon-boulevard: "\e900";
$icon-popover: "\1f5e8";
$icon-balloon: "\1f5e9";
$icon-facebook: "\e903";
$icon-instagram: "\e904";
$icon-twitter: "\e905";
$icon-menu: "\ec71";
$icon-close: "\78";
$icon-chevron-right: "\276f";
$icon-chevron-left: "\276e";