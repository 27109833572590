.block-mapWithPoints {

}

//---------- map ----------
.mapWithPoints-map {
	width: 100%;
	height: 575px;
}


//---------- popup ----------
.customPopover {
	position: relative;
}

.customPopoverContent {
	//@extend .svgMapPopover;
	display: block;
}

.customPopoverContentInner {
	@extend .clearfix;
	padding: 11px;
	background-color: $c-mineShaft;
	color: #fff;
	border-radius: 69px;
	min-height: 138px;

	&.customPopover--hasImage {
		img {
			float: left;
		}
	}

	img {
		border-radius: 58px;
	}

	div {
		float: right;
		@extend .text-content;
		@include fontsize(14px);
		text-transform: uppercase;
		max-width: 160px;
		padding: 20px;
	}

	&:before {
		@extend %icon-font;
		content: $icon-triangle-down;
		color: $c-mineShaft;
		@include fontsize(16px);
		position: absolute;
		bottom: -15px;
		left: 50%;
		margin-left: -8px;
	}
}

.customPopover-pointNumber {
	display: block;
	line-height: 1;
}

.customPopover-title {
	display: block;
	margin-top: $marginDefault * .5;
	line-height: 1.3;
}

//---------- main marker ----------
.commonMarker {
	position: relative;
}
.bubbleMarkerIcon {
	position: relative;
	cursor: pointer;
	bottom: 100%;
	left: - $d-mapBalloonIconWidth * .5;
	top: - $d-mapBalloonIconHeight;

	&:before {
		color: $c-mineShaft;
		@extend %icon-font;
		content: $icon-balloon;
		position: relative;
		z-index: 1;
		@include fontsize($fs-mapBalloonIcon);
		outline: 1px solid transparent; //fixes an svg edge cutoff issue
	}

	&:after {
		content: attr(data-point-number);
		position: absolute;
		z-index: 2;
		top: 10px;
		left: 0;
		width: 100%;
		text-align: center;
		@include fontsize($fs-mapBalloonNumber);
		font-weight: normal;
		color: #fff;
		font-family: $ff-default;
		line-height: 1;
	}
}

//---------- rows ----------
.mapWithPoints-rowInner {
	text-align: center;
}

.mapWithPoints-heading {
	padding-right: $fs-mapBalloonIcon;
	position: relative;
	margin-top: 0;
	min-height: 44px;
}

.mapWithPoints-number {
	position: absolute;
	display: inline-block;
	color: #fff;
	vertical-align: middle;
	left: 50%;
	top: 0;
	@include fontsize(0);
	transform: translateX(-50%);

	&:before {
		@extend %icon-font;
		content: $icon-balloon;
		@include fontsize($fs-mapBalloonIcon);
		position: relative;
		z-index: 1;
		color: $c-mineShaft;
		top: 0;
		outline: 1px solid transparent; //fixes an svg edge cutoff issue
	}

	&:after {
		content: attr(data-point-number);
		position: absolute;
		z-index: 2;
		top: 10px;
		left: 0;
		width: 100%;
		text-align: center;
		@include fontsize($fs-mapBalloonNumber);
		font-weight: normal;
		line-height: 1;
	}
}