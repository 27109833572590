.listingBlock-items {
	@extend .clearfix;
	@include fontsize(0);
	text-align: center;
	padding: 5px;
	display: flex;
	flex-wrap: wrap;
}

.listingBlock-item {
	width: 100%;
	text-decoration: none;
	vertical-align: top;
	background-color: $c-gallery;
	margin: 0;
	border: 5px solid $c-alto;
	padding: $paddingDefault*2 $paddingDefault;
	display: inline-block;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	@include query_min($bp-small) {
		width: 50%;
	}
	@include query_min($bp-mobile) {
		padding: $paddingDefault*2.7 $paddingDefault*3.6;
	}
}

.listingBlock-icon {
	display: block;
	margin: 0 auto;
	width: 114px;

	img[src$=".svg"] {
		visibility: hidden;
	}

	line, polyline, path, rect, polygon, circle {
		fill: $c-emperor;
	}
}

.listingBlock-title {
	@include fontsize($fs-listingTitle);
	line-height: $lh-listingTitle;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
}

.listingBlock-label {
	display: block;
	@include fontsize($fs-listingLabel);
	line-height: $lh-listingLabel;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: $marginDefault*1.35;
	cursor: default;
	flex-grow: 2;
}

.listingBlock-button {
	margin-top: $marginDefault*2.35;
	margin-left: auto;
	margin-right: auto;
}

.listingBlock-summary {
	@include fontsize($fs-listingSummary);
	line-height: $lh-listingSummary;
	text-align: left;
}

.listingBlock-pagination {
	background-color: $c-gallery;
	border: 10px solid $c-alto;
	border-top: none;
}

.listing-date {
	text-align: center;
}

.listingBlock-month {
	@extend .listingBlock-title;
	display: block;
}

.listingBlock-day {
	@include fontsize($fs-listingDay);
	line-height: $lh-listingDay;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	margin-top: 0;
	display: block;
}